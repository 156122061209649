import { useEffect } from 'react'

export const useComeBacker = () => {
  useEffect(() => {
    window.addEventListener(
      'load',
      function () {
        window.history.pushState(-1, null)
        window.history.pushState(0, null)
        window.history.pushState(1, null)
        window.history.go(-1)
        window.addEventListener(
          'popstate',
          async (event) => {
            event.preventDefault()

            if (event.state === -1) {
              window.location.href = 'https://gl.guruleads.ru/click/23000/1759?erid=LjN8KJTJn'
            }
          },
          false,
        )
      },
      false,
    )
  }, [])
}
